@import 'declarations';

:root {
  font-size: 16px;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

h1,
.h1 {
  font-size: 56px !important;
  @include media-breakpoint-down(sm) {
    font-size: 32px !important;
  }
}

h2,
.h2 {
  font-size: 40px !important;
  @include media-breakpoint-down(sm) {
    font-size: 26px !important;
  }
}

h3,
.h3 {
  font-size: 28px !important;
  @include media-breakpoint-down(sm) {
    font-size: 22px !important;
  }
}

h4,
.h4 {
  font-size: 20px !important;
  @include media-breakpoint-down(sm) {
    font-size: 18px !important;
  }
}

h5,
.h5 {
  font-size: 18px !important;
  @include media-breakpoint-down(sm) {
    font-size: 16px !important;
  }
}

h6,
.h6 {
  font-size: 16px !important;
  @include media-breakpoint-down(sm) {
    font-size: 14px !important;
  }
}

.f-80 {
  font-size: 80px;
}
